import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../sdk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function formatDateFromObject(dateObj) {
  const { year, month, day } = dateObj;
  const formattedDay = day.toString().padStart(2, "0");
  const formattedMonth = month.toString().padStart(2, "0");
  return `${formattedDay}-${formattedMonth}-${year}`;
}

export default function useAminEvents() {
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [options, setOptions] = useState([]);
  const [networkMsg, setnetworkMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState("generic");
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [radius, setRadius] = useState(10);
  const [view, setView] = useState("map");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const { authFetch, currentHotel } = useAuth();
  const today = new Date();
  const oneMonthLater = new Date(today);
  const { hotelId } = useParams();

  oneMonthLater.setMonth(today.getMonth() + 1);

  const fromDateDefaultValue = {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  };

  const toDateDefaultValue = {
    year: oneMonthLater.getFullYear(),
    month: oneMonthLater.getMonth() + 1,
    day: oneMonthLater.getDate(),
  };
  const [dateNew, setDateNew] = useState({
    from: fromDateDefaultValue,
    to: toDateDefaultValue,
  });

  const getEventTypes = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/event-categories`,
      });
      const { data } = await get();
      if (data) {
        setEventTypes(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch]);
  const getCities = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/keyword-cities`,
      });
      const { data, response } = await get();

      if (response?.ok) {
        setCityList(data);
      } else {
        setnetworkMsg(data?.messageToUser || "Something Went Wrong");
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch]);

  useEffect(() => {
    if (type === "city_based") getCities();
  }, [getCities, type]);
  const getKeywords = useCallback(async () => {
    const body = {
      keyword_type: type,
      cities: type === "generic" ? null : city?.length > 0 ? city?.join() : "",
    };
    try {
      const { get } = await authFetch({
        path: `/keywordsv2`,
      });
      const { data } = await get(`?${new URLSearchParams(body)}`);
      if (data) {
        setOptions(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, city, type]);

  const getEventDetails = useCallback(async () => {
    try {
      setLoading(true);
      const words = selectedKeywords?.map((val) => val.keywordName);

      const body = {
        categoryId: selectedType?.length > 0 ? selectedType?.join() : "",
        fromDate: formatDateFromObject(dateNew?.from),
        toDate: formatDateFromObject(dateNew?.to),
        limit: rowsPerPage,
        page: page,
        search: searchInput,
        keyword: words.length > 0 ? words.join() : "",
      };
      const { get } = await authFetch({
        path: `/all-events`,
      });
      const { data, response } = await get(`?${new URLSearchParams(body)}`);

      if (response.status === 400) {
        setError(data.messageToUser);
      }
      if (data) {
        setEventDetails(data);
        setCount(data?.total_count);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [
    selectedKeywords,
    selectedType,
    dateNew.from,
    dateNew.to,
    rowsPerPage,
    page,
    searchInput,
    authFetch,
  ]);

  const downloadFile = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/download-all-events`,
    });
    const words = selectedKeywords?.map((val) => val.name);

    const body = {
      categoryId: selectedType?.length > 0 ? selectedType?.join() : "",
      radius: `0-${radius}`,
      //   fromDate: formatDateFromObject(fromDateNew),
      //   toDate: formatDateFromObject(toDateNew),
      keyword: words.length > 0 ? words.join() : "",
    };

    const response = await get(`?${new URLSearchParams(body)}`);
    const blob = await response.response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `All_Events.xlsx`);
    document.body.appendChild(link);
    link.click();
  }, [authFetch, hotelId, radius, selectedKeywords, selectedType]);

  const handleAdd = useCallback(async () => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/all-events`,
    });
    const { data, response } = await post({
      ids: selectedRows,
    });
    if (response.status === 200) {
      setnetworkMsg("Event Added Successfully");
      getEventDetails();
    } else {
      setnetworkMsg(data?.messageToUser || "Something Went Wrong");
    }
    setSelectedRows([]);
  }, [authFetch, getEventDetails, hotelId, selectedRows]);

  useEffect(() => {
    getEventTypes();
  }, [getEventTypes]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getEventDetails();
    }, 200);
    return () => clearTimeout(timer);
  }, [getEventDetails]);

  useEffect(() => {
    getKeywords();
  }, [getKeywords]);

  return {
    eventTypes,
    selectedType,
    setSelectedType,
    radius,
    setRadius,
    view,
    setView,
    dateNew,
    setDateNew,
    eventDetails,
    error,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    setPage,
    rowsPerPage,
    count,
    selectedRows,
    setSelectedRows,
    handleAdd,
    networkMsg,
    setnetworkMsg,
    searchInput,
    setSearchInput,
    options,
    searchKeyword,
    setSearchKeyword,
    selectedKeywords,
    setSelectedKeywords,
    downloadFile,
    loading,
    setType,
    type,
    openSearch,
    setOpenSearch,
    cityList,
    city,
    setCity,
  };
}
