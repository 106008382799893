import {
  Button,
  Paper,
  Popover,
  Skeleton,
  Stack,
  TableBody,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useAuth } from "../../sdk";
import { Calendar, Cell, GridBox, Name, Week } from "../Styles";
import Footer from "./Footer";
import { SwapVert } from "@mui/icons-material";

let alerts = new Array(5).fill("Hotel Lalit went 3% down your rate");

export const PriceCheckerCalendar = ({
  calendarData,
  channels,
  filters,
  clickonRefreshBtn,
  isLoading,
  canPostRateMetric,
  canRateMetricLoading,
  showSkeleton,
}) => {
  const sourceImages = {
    "Booking.com": "/assets/booking.svg",
    "Expedia.co.in": "/assets/expedia.svg",
    Agoda: "/assets/agoda.svg",
    Cleartrip: "/assets/cleartrip.svg",
    Makemytrip: "/assets/makemytrip.svg",
    Goibibo: "/assets/goibibo.svg",
  };
  const [openPopoverIndex, setOpenPopoverIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { currentHotel } = useAuth();
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = React.useState(false);

  const compareDates = useCallback(
    (index) => {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set time to 00:00:00:000 for comparison
      const rateDate = new Date(calendarData[index]?.rateDate);

      if (
        rateDate.getTime() >= currentDate.getTime() &&
        !calendarData[index]?.isSearching
      ) {
        return true;
      } else {
        return false;
      }
    },
    [calendarData]
  );

  const handlePopoverOpen = (event, index) => {
    console.log(index);
    setAnchorEl(event.currentTarget);
    setOpenPopoverIndex(index);
  };

  const handlePopoverClose = (index) => {
    setAnchorEl(null);
    setOpenPopoverIndex(null);
  };

  useEffect(() => {
    if (isLoading) {
      handlePopoverClose();
    }
  }, [isLoading]);

  const openAlertDialog = () => {
    setOpenAlert(true);
  };

  const closeAlertDialog = () => {
    setOpenAlert(false);
  };

  const color = useCallback((val, ispast) => {
    if (ispast)
      return `linear-gradient(180deg, #138C11 0%, rgba(19, 140, 17, 0.60) 51.5%, rgba(19, 140, 17, 0.30) 96.87%),linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5))`;
    else {
      return `linear-gradient(180deg, #138C11 0%, rgba(19, 140, 17, 0.60) 51.5%, rgba(19, 140, 17, 0.30) 96.87%)`;
    }
  }, []);

  const backgroundColor = useCallback((value, date) => {
    switch (true) {
      case value === 0:
        return "#52B6FF";
      case value < 0:
        return "linear-gradient(180deg, #138C11 0%, rgba(19, 140, 17, 0.60) 51.5%, rgba(19, 140, 17, 0.30) 96.87%)";
      case value > 0:
        return "linear-gradient(180deg, #D30E08 0%, rgba(211, 14, 8, 0.60) 51.5%, rgba(211, 14, 8, 0.30) 96.87%)";

      default:
        return "#949494";
    }
  }, []);

  const isPastDate = useCallback((value) => {
    const date = new Date(value);
    const today = new Date().setHours(0, 0, 0, 0);
    return date.getTime() < today;
  }, []);

  const cancellationPolicy = useCallback((value) => {
    switch (true) {
      case value === 1:
        return "(NFR)";
      case value === 2:
        return "(FLEX)";
      case value === 3:
        return "(SEMI FLEX)";
      default:
        return "";
    }
  }, []);

  return (
    <>
      <Calendar>
        <Week>
          <Name>Monday</Name>
          <Name>Tuesday</Name>
          <Name>Wednesday</Name>
          <Name>Thursday</Name>
          <Name>Friday</Name>
          <Name>Saturday</Name>
          <Name>Sunday</Name>
        </Week>
        {isLoading && showSkeleton ? (
          <GridBox>
            {[...Array(35)]?.map((_, index) => (
              <Cell>
                <Skeleton key={index} variant="rounded" height={60} />
              </Cell>
            ))}
          </GridBox>
        ) : (
          <GridBox>
            {clickonRefreshBtn &&
              calendarData?.map((data, index) => {
                if (data === "") {
                  return <Cell></Cell>;
                }
                return data.minHotelPrice !== "" ? (
                  <Cell
                    key={index}
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    <Paper
                      elevation={5}
                      sx={{
                        opacity: data?.isSearching && "0.4",
                        minHeight: "65px",
                        position: "relative",
                        color: "white",
                        background:
                          data?.hotelResult?.minPrice === 0
                            ? "#ADB3AD"
                            : backgroundColor(
                                data?.hotelResult?.minPrice -
                                  data?.meanCompetitorPrice,
                                data?.rateDate
                              ),
                        borderRadius: "10px",
                      }}
                    >
                      {data?.isSearching && (
                        <StyledSearching
                          component="img"
                          src="/assets/loading.svg"
                          alt="refreshing"
                          title="Processing"
                          sx={{ position: "absolute", top: "15%", left: "45%" }}
                        />
                      )}
                      <Box p={1}>
                        <Stack
                          direction={"row"}
                          justifyContent="space-between"
                          onMouseEnter={(event) => {
                            if (!anchorEl) handlePopoverOpen(event, index);
                          }}
                        >
                          <Stack
                            display="flex"
                            flexDirection={{
                              sm: "column",
                              md: "row",
                            }}
                            justifyContent="space-between"
                            width={"100%"}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              {data?.rateDate.slice(8, 10)}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              {data?.hotelResult?.internalStatus ===
                                "available" &&
                                (currentHotel?.RSCurrency === "INR"
                                  ? "₹"
                                  : "$") + data?.hotelResult?.minPrice}
                            </Typography>
                            {data?.hotelResult &&
                              data?.hotelResult?.internalStatus !==
                                "sold_out" && (
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {data?.occupancy && data?.occupancy !== 0
                                    ? `${(data?.occupancy).toFixed(2)}%`
                                    : "--"}
                                </Typography>
                              )}
                          </Stack>
                        </Stack>
                        {!data?.isSearching &&
                        data?.hotelResult === undefined &&
                        compareDates(index) ? (
                          <Button
                            onClick={() =>
                              canPostRateMetric({
                                email: [],
                                refreshDate: data?.rateDate,
                              })
                            }
                            sx={{
                              color: "#fff",
                              textTransform: "capitalize",
                              padding: "0px",
                            }}
                            variant="text"
                            disabled={canRateMetricLoading}
                          >
                            Refresh
                          </Button>
                        ) : (
                          !data?.isSearching &&
                          data?.hotelResult === undefined &&
                          !compareDates(index) &&
                          "No data available "
                        )}
                        {data?.hotelResult?.internalStatus === "available" && (
                          <Stack
                            direction={{
                              md: "column",
                              lg: "row",
                            }}
                            position="relative"
                            justifyContent="space-around"
                            onMouseEnter={(event) => {
                              if (!anchorEl) handlePopoverOpen(event, index);
                            }}
                          >
                            {!!data?.meanCompetitorPrice && (
                              <>
                                {!!data?.hotelResult?.minPrice &&
                                  !isNaN(data?.meanCompetitorPrice) && (
                                    <Typography
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        mt: 2,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {`${Math.abs(
                                        ((data?.hotelResult?.minPrice -
                                          data?.meanCompetitorPrice) /
                                          data?.meanCompetitorPrice) *
                                          100
                                      ).toFixed(2)}%`}
                                    </Typography>
                                  )}
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    mt: 2,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  vs Comp
                                </Typography>
                              </>
                            )}

                            {!!data?.hotelResult?.sourceSortedArray?.find(
                              ({ sourceId }) =>
                                sourceId === filters?.channelIds?.[0]
                            )?.isMealIncluded ? (
                              <Box
                                component="img"
                                // position="absolute"
                                // right="1px"
                                // top="1px"
                                sx={{
                                  width: "20px",
                                  alignSelf: "end",
                                  paddingBottom: "10px",
                                }}
                                src="/assets/Meal-Included.svg"
                                alt="meal included"
                              />
                            ) : (
                              <Box
                                component="img"
                                // position="absolute"
                                // right="1px"
                                // top="1px"
                                sx={{
                                  width: "20px",
                                  alignSelf: "end",
                                  paddingBottom: "10px",
                                }}
                                src="/assets/Meal-Not-Included.svg" // Change the image path to the image for not included
                                alt="meal not included"
                              />
                            )}
                          </Stack>
                        )}
                        {data?.hotelResult?.internalStatus === "sold_out" && (
                          <Typography fontSize="14px" fontWeight={700}>
                            Sold-out
                          </Typography>
                        )}
                        {data?.hotelResult?.internalStatus === "error" && (
                          <Tooltip title={data?.hotelResult?.errMsg}>
                            <ReportProblemIcon />
                          </Tooltip>
                        )}

                        <Stack
                          direction={"row"}
                          justifyContent={"flex-end"}
                          spacing={0.5}
                          onClick={openAlertDialog}
                          sx={{ cursor: "pointer" }}
                        >
                          {/* as per rishita mam currently 2 alerts is commented, uncomment this when to use */}
                          {/* <WarningAmberRoundedIcon
                                                    sx={{
                                                        color: "#F43535",
                                                        fontSize: "medium",
                                                    }}
                                                />
                                                <Typography
                                                    style={{
                                                        fontSize: "10px",
                                                        fontWeight: 500,
                                                        marginTop: "3px",
                                                    }}
                                                >
                                                    2 Alerts
                                                </Typography> */}
                          {/* <RestaurantRoundedIcon sx={{
                                            fontSize: ' medium',
                                            borderRadius: '50%',
                                            border: '1px solid black',
                                            p: 0.2
                                        }} /> */}
                        </Stack>
                      </Box>
                    </Paper>
                  </Cell>
                ) : (
                  <Cell key={index} style={{ userSelect: "none" }}>
                    <Paper
                      elevation={5}
                      sx={{
                        minHeight: {
                          lg: "65px",
                          md: "110px",
                          sm: "150px",
                        },
                        background: "white",
                        borderRadius: "10px",
                      }}
                    >
                      {data?.isSearching && (
                        <StyledSearching
                          component="img"
                          src="/assets/loading.svg"
                          alt="refreshing"
                          title="Processing"
                          sx={{ position: "absolute", top: "15%", left: "45%" }}
                        />
                      )}
                      <Box
                        p={1}
                        onMouseEnter={(event) => {
                          if (!anchorEl) handlePopoverOpen(event, index);
                        }}
                      >
                        <Stack direction={"row"} justifyContent="space-between">
                          <Stack direction="column">
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              {data.rateDate.slice(8, 10)}
                              {""}
                              {new Date(data.rateDate).toLocaleString("en-us", {
                                month: "short",
                              })}
                            </Typography>
                            {/* <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {new Date(data.rateDate).toLocaleString("en-us", {
                              month: "short",
                            })}
                          </Typography> */}
                          </Stack>
                        </Stack>
                        <Typography>-</Typography>
                      </Box>
                    </Paper>
                  </Cell>
                );
              })}

            {/* when resfresh button is disabled loader will be shown */}
            {!clickonRefreshBtn && (
              <Box
                sx={{
                  position: "absolute",
                  width: "85%",
                  height: "500px",
                  backgroundColor: "rgba(217, 217, 217, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  zIndex: 100,
                }}
              >
                <Typography
                  align="center"
                  paragraph
                  color={"#303030"}
                  sx={{ fontSize: "20px", fontWeight: 500 }}
                >
                  <CircularProgress sx={{ color: "black" }} />
                </Typography>
              </Box>
            )}

            {/* when no data is coming on hitting rate shop api show this */}
            {calendarData.length === 0 && (
              <Box
                sx={{
                  position: "absolute",
                  width: "94%",
                  height: "450px",
                  backgroundColor: "rgba(217, 217, 217, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  zIndex: 100,
                }}
              >
                <Typography
                  align="center"
                  paragraph
                  color={"#303030"}
                  sx={{ fontSize: "20px", fontWeight: 500 }}
                >
                  Please perform the live shop and
                  <br />
                  click on "Refresh" button
                </Typography>
              </Box>
            )}
          </GridBox>
        )}
      </Calendar>
      <Footer />

      {/* on hover of each cell open popover */}
      {openPopoverIndex !== null && (
        <Popover
          sx={{
            "& .MuiPopover-paper": {
              borderRadius: "8px",
              background: "#FCFCFC",
              minWidth: "500px",
              minHeight: "200px",
              boxShadow: "0px 8px 14px 0px  rgba(48, 111, 188, 0.2)",
            },
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box
            sx={{ p: 3 }}
            onMouseLeave={() => {
              handlePopoverClose();
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                {new Date(calendarData[openPopoverIndex]?.rateDate)
                  .toLocaleString("en-IN", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                  .replace(/,/g, "")}{" "}
                {!!calendarData[openPopoverIndex]?.meanCompetitorPrice &&
                  calendarData[openPopoverIndex]?.hotelResult
                    ?.internalStatus === "available" && (
                    <>
                      (Average Comp Rate -{" "}
                      {currentHotel.RSCurrency === "INR" ? "₹" : "$"}
                      {Math.round(
                        calendarData[openPopoverIndex]?.meanCompetitorPrice
                      )}
                      )
                    </>
                  )}
              </Typography>

              {compareDates(openPopoverIndex) && !canRateMetricLoading && (
                <StyledRefreshIcon
                  variant="contained"
                  title="Refresh"
                  onClick={() =>
                    canPostRateMetric({
                      email: [],
                      refreshDate: calendarData[openPopoverIndex]?.rateDate,
                    })
                  }
                >
                  <SwapVert
                    sx={{
                      color: "blue",
                    }}
                  ></SwapVert>
                </StyledRefreshIcon>
              )}
              {(calendarData[openPopoverIndex]?.isSearching ||
                canRateMetricLoading) && (
                <StyledSearching
                  component="img"
                  src="/assets/loading.svg"
                  alt="refreshing"
                  title="Processing"
                />
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mr={1}
            >
              {calendarData[openPopoverIndex]?.latestUpdated !==
                "0001-01-01T00:00:00Z" && (
                <Typography variant="caption">
                  {" "}
                  Latest updated at{" "}
                  {moment(
                    calendarData[openPopoverIndex]?.latestUpdated
                  ).fromNow()}{" "}
                </Typography>
              )}
              {calendarData[openPopoverIndex]?.occupancy !== 0 && (
                <Typography variant="caption">
                  Hotel Occ. -{" "}
                  {` ${calendarData[openPopoverIndex]?.occupancy.toFixed(2)}%`}
                </Typography>
              )}
            </Stack>

            {filters.channelIds.length > 1 ? (
              <Table sx={{ mt: 1 }} size={"small"}>
                <TableHead
                  sx={{
                    borderTop: "0.5px solid #787777",
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "0.5px solid #787777",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>Hotel Names</TableCell>
                    <TableCell align="right">Rate</TableCell>
                    <TableCell align="right">Cheapest Channel</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                    },
                  }}
                >
                  <>
                    {calendarData[openPopoverIndex]?.hotelResult && (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: "#163A90",
                          }}
                        >
                          {calendarData[openPopoverIndex]?.hotelResult
                            .hotelName ?? "--"}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#163A90",
                          }}
                        >
                          {calendarData[
                            openPopoverIndex
                          ]?.hotelResult?.sourceSortedArray?.find(
                            ({ sourceId }) =>
                              sourceId === filters?.channelIds?.[0]
                          )?.isSoldOut
                            ? "Sold Out"
                            : calendarData[
                                openPopoverIndex
                              ]?.hotelResult?.sourceSortedArray?.find(
                                ({ sourceId }) =>
                                  sourceId === filters?.channelIds?.[0]
                              )?.price
                            ? (currentHotel.RSCurrency === "INR" ? "₹" : "$") +
                              calendarData[
                                openPopoverIndex
                              ]?.hotelResult?.sourceSortedArray?.find(
                                ({ sourceId }) =>
                                  sourceId === filters?.channelIds?.[0]
                              )?.price
                            : "--"}
                        </TableCell>
                        {calendarData[openPopoverIndex]?.hotelResult
                          .sourceSortedArray && (
                          <React.Fragment>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#163A90",
                              }}
                            >
                              <img
                                src={
                                  sourceImages[
                                    channels.find(
                                      (channel) =>
                                        channel.sourceId ===
                                        calendarData[openPopoverIndex]
                                          ?.hotelResult.sourceSortedArray?.[0]
                                          .sourceId
                                    ).name
                                  ]
                                }
                                alt="channel"
                              />
                              {/* {[
                                  channels.find(
                                      (channel) =>
                                          channel.sourceId ===
                                          calendarData[
                                              openPopoverIndex
                                          ]
                                              ?.hotelResult
                                              .sourceSortedArray?.[0]
                                              .sourceId
                                  ).name,
                              ]} */}
                            </TableCell>

                            <TableCell
                              align="right"
                              sx={{
                                color: "#163A90",
                              }}
                            >
                              {calendarData[
                                openPopoverIndex
                              ]?.hotelResult.sourceSortedArray
                                ?.sort((a, b) => b.price - a.price)
                                ?.map((sourceItem) => {
                                  const source = channels?.find(
                                    (channel) =>
                                      channel.sourceId === sourceItem.sourceId
                                  );

                                  return (
                                    <React.Fragment key={source.id}>
                                      <img
                                        src={sourceImages[source.name]}
                                        alt={source.name}
                                      />{" "}
                                    </React.Fragment>
                                  );
                                })}
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    )}

                    {calendarData[openPopoverIndex]?.competitorsResults &&
                      calendarData[openPopoverIndex].competitorsResults?.map(
                        (item, index) => {
                          const lowestPriceItem = item.sourceSortedArray?.reduce(
                            (acc, curr) => {
                              return acc.price < curr.price ? acc : curr;
                            }
                          );
                          item.sourceSortedArray.sort(
                            (a, b) => b.price - a.price
                          );
                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {item?.hotelName}
                              </TableCell>
                              <TableCell align="right">
                                {item.isSoldOut
                                  ? "Sold Out"
                                  : (currentHotel.RSCurrency === "INR"
                                      ? "₹"
                                      : "$") + item?.minPrice}
                              </TableCell>
                              <TableCell align="center">
                                <img
                                  src={
                                    sourceImages[
                                      channels.find(
                                        (channel) =>
                                          channel.sourceId ===
                                          lowestPriceItem.sourceId
                                      ).name
                                    ]
                                  }
                                  alt={lowestPriceItem.sourceId}
                                />{" "}
                                {/* {[
                                                                    channels.find(
                                                                        (
                                                                            channel
                                                                        ) =>
                                                                            channel.sourceId ===
                                                                            lowestPriceItem.sourceId
                                                                    ).name,
                                                                ]} */}
                              </TableCell>

                              <TableCell align="right">
                                {item.sourceSortedArray?.map((sourceItem) => {
                                  const source = channels?.find(
                                    (channel) =>
                                      channel.sourceId === sourceItem.sourceId
                                  );
                                  return (
                                    <React.Fragment key={source.id}>
                                      <img
                                        src={sourceImages[source.name]}
                                        alt={source.name}
                                      />{" "}
                                    </React.Fragment>
                                  );
                                })}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </>
                </TableBody>
              </Table>
            ) : (
              <Table sx={{ mt: 1 }} size={"small"}>
                <TableHead
                // sx={{
                //   borderTop: "0.5px solid #787777",
                //   [`& .${tableCellClasses.root}`]: {
                //     borderBottom: "0.5px solid #787777",
                //   },
                // }}
                >
                  <TableRow>
                    <TableCell>Hotel Name</TableCell>
                    <TableCell align="center">Rate</TableCell>
                    <TableCell align="left">Room Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                    },
                  }}
                >
                  <>
                    {calendarData[openPopoverIndex]?.hotelResult && (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: "#163A90",
                          }}
                        >
                          {calendarData[openPopoverIndex]?.hotelResult.hotelName
                            ?.length
                            ? calendarData[openPopoverIndex]?.hotelResult
                                .hotelName
                            : "--"}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#163A90" }}>
                          {calendarData[
                            openPopoverIndex
                          ]?.hotelResult.sourceSortedArray?.find(
                            ({ sourceId }) =>
                              sourceId === filters?.channelIds?.[0]
                          )?.isSoldOut
                            ? "Sold Out"
                            : calendarData[
                                openPopoverIndex
                              ]?.hotelResult.sourceSortedArray?.find(
                                ({ sourceId }) =>
                                  sourceId === filters?.channelIds?.[0]
                              )?.price
                            ? (currentHotel.RSCurrency === "INR" ? "₹" : "$") +
                              calendarData[
                                openPopoverIndex
                              ]?.hotelResult.sourceSortedArray?.find(
                                ({ sourceId }) =>
                                  sourceId === filters?.channelIds?.[0]
                              )?.price
                            : "--"}
                        </TableCell>

                        <TableCell align="left" sx={{ color: "#163A90" }}>
                          <Stack
                            sx={{
                              alignItems: "center",
                              gap: "8px",
                            }}
                            flexDirection="row"
                          >
                            <Typography
                              sx={{
                                overflow: "hidden",
                                maxWidth: "20ch",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <Tooltip
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -14],
                                        },
                                      },
                                    ],
                                  },
                                }}
                                title={
                                  calendarData[
                                    openPopoverIndex
                                  ]?.hotelResult?.sourceSortedArray?.find(
                                    (value) =>
                                      value.sourceId ===
                                      filters?.channelIds?.[0]
                                  )?.roomType?.length
                                    ? calendarData[
                                        openPopoverIndex
                                      ]?.hotelResult?.sourceSortedArray?.find(
                                        (value) =>
                                          value?.sourceId ===
                                          filters?.channelIds?.[0]
                                      )?.roomType
                                    : "--"
                                }
                              >
                                {calendarData[
                                  openPopoverIndex
                                ]?.hotelResult?.sourceSortedArray?.find(
                                  (value) =>
                                    value.sourceId === filters?.channelIds?.[0]
                                )?.roomType?.length
                                  ? calendarData[
                                      openPopoverIndex
                                    ]?.hotelResult?.sourceSortedArray?.find(
                                      (value) =>
                                        value?.sourceId ===
                                        filters?.channelIds?.[0]
                                    )?.roomType
                                  : "--"}{" "}
                              </Tooltip>
                            </Typography>
                            {!!calendarData[
                              openPopoverIndex
                            ]?.hotelResult?.sourceSortedArray?.find(
                              (value) =>
                                value.sourceId === filters?.channelIds?.[0]
                            )?.isMealIncluded ? (
                              <Box
                                component="img"
                                sx={{
                                  width: "20px",
                                  alignSelf: "end",
                                }}
                                src="/assets/Meal.svg"
                                alt="meal included"
                              />
                            ) : (
                              <Box
                                component="img"
                                sx={{
                                  width: "20px",
                                  alignSelf: "end",
                                  paddingTop: "4px",
                                }}
                                src="/assets/No-Meal.svg" // Change the image path to the image for not included
                                alt="meal not included"
                              />
                            )}
                            {cancellationPolicy(
                              calendarData[
                                openPopoverIndex
                              ]?.hotelResult?.sourceSortedArray?.find(
                                (value) =>
                                  value.sourceId === filters?.channelIds?.[0]
                              )?.conditionCode
                            )}
                            <Typography alignItems="center">
                              {`(${
                                calendarData[
                                  openPopoverIndex
                                ]?.hotelResult?.sourceSortedArray?.find(
                                  (value) =>
                                    value?.sourceId === filters?.channelIds?.[0]
                                )?.cancellationStatus
                              })`}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}

                    {calendarData[openPopoverIndex]?.competitorsResults &&
                      calendarData[openPopoverIndex].competitorsResults?.map(
                        (item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {item?.hotelName?.length
                                  ? item?.hotelName
                                  : "--"}
                              </TableCell>
                              <TableCell align="center">
                                {item.internalStatus === "available" &&
                                  (currentHotel.RSCurrency === "INR"
                                    ? "₹"
                                    : "$") + item?.minPrice}
                                {item.internalStatus === "sold_out" &&
                                  "Sold-out"}
                                {item.internalStatus === "error" && (
                                  <Tooltip title={item.errMsg}>
                                    <ReportProblemIcon />
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Stack
                                  flexDirection="row"
                                  alignItems="ceter"
                                  gap="8px"
                                >
                                  <Typography
                                    sx={{
                                      overflow: "hidden",
                                      maxWidth: "20ch",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <Tooltip
                                      slotProps={{
                                        popper: {
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -14],
                                              },
                                            },
                                          ],
                                        },
                                      }}
                                      title={
                                        item?.sourceSortedArray?.find(
                                          (value) =>
                                            value?.sourceId ===
                                            filters?.channelIds?.[0]
                                        )?.internalStatus === "available"
                                          ? item?.sourceSortedArray?.find(
                                              (value) =>
                                                value?.sourceId ===
                                                filters?.channelIds?.[0]
                                            )?.roomType
                                          : "--"
                                      }
                                    >
                                      {item?.sourceSortedArray?.find(
                                        (value) =>
                                          value?.sourceId ===
                                          filters?.channelIds?.[0]
                                      )?.internalStatus === "available"
                                        ? item?.sourceSortedArray?.find(
                                            (value) =>
                                              value?.sourceId ===
                                              filters?.channelIds?.[0]
                                          )?.roomType
                                        : "--"}
                                    </Tooltip>
                                  </Typography>

                                  {item.internalStatus === "available" &&
                                    (!!item?.sourceSortedArray?.find(
                                      (value) =>
                                        value?.sourceId ===
                                        filters?.channelIds?.[0]
                                    )?.isMealIncluded ? (
                                      <Box
                                        component="img"
                                        sx={{
                                          width: "20px",
                                          alignSelf: "end",
                                        }}
                                        src="/assets/Meal.svg"
                                        alt="meal included"
                                      />
                                    ) : (
                                      <Box
                                        component="img"
                                        sx={{
                                          width: "20px",
                                          alignSelf: "end",
                                        }}
                                        src="/assets/No-Meal.svg" // Change the image path to the image for not included
                                        alt="meal not included"
                                      />
                                    ))}
                                  {cancellationPolicy(
                                    item?.sourceSortedArray?.find(
                                      (value) =>
                                        value.sourceId ===
                                        filters?.channelIds?.[0]
                                    )?.conditionCode
                                  )}
                                  <Typography alignItems="center">
                                    {`(${
                                      item?.sourceSortedArray?.find(
                                        (value) =>
                                          value.sourceId ===
                                          filters?.channelIds?.[0]
                                      )?.cancellationStatus
                                    })`}
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </>
                </TableBody>
              </Table>
            )}
          </Box>
        </Popover>
      )}

      {/* on clicking alerts box opens dialog */}
      {/* as per rishita mam currently 2 alerts is commented so is the dialog box opening on its click is commented, uncomment this when to use */}
      {/* <Dialog
                PaperProps={{
                    sx: {
                        minHeight: "270px",
                        width: "557px",
                        background: "#FFFFFF",
                        borderRadius: "8px",
                    },
                }}
                open={openAlert}
                onClose={closeAlertDialog}
            >
                <DialogContent>
                    <Stack direction={"row"} justifyContent={"space-between"} pb={2}>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: "22px",
                                lineHeight: "20px",
                                color: "#FE0808",
                            }}
                        >
                            5 Alerts:
                        </Typography>
                        <CloseIcon
                            sx={{ color: "#4F5A6A", fontSize: "small", cursor: "pointer" }}
                            onClick={closeAlertDialog}
                        />
                    </Stack>
                    {alerts.map((item, index) => (
                        <Typography
                            key={item}
                            sx={{
                                fontSize: "22px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                color: "#000000",
                                mt: 3,
                                ml: 4,
                            }}
                        >
                            {index + 1}. {item}
                        </Typography>
                    ))}
                </DialogContent>
            </Dialog> */}

      {/* ui for sold out hotels cell*/}
      {/* <Cell
                        style={{ cursor: "pointer", userSelect: "none" }}
                    >
                        <Paper
                            elevation={5}
                            style={{
                                height: "121px",
                                background: '#9EA19E',
                                borderRadius: "10px",
                            }}
                        >
                            <Box p={1}>
                                <Stack
                                    direction={"row"}
                                    justifyContent="space-between"
                                >
                                    <Stack
                                        direction="column">
                                        <Typography
                                            style={{
                                                fontSize: "14px",
                                                fontWeight: 500
                                            }}
                                        >
                                            02
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: "14px",
                                                fontWeight: 500
                                            }}
                                        >
                                            Feb
                                        </Typography>
                                    </Stack>

                                    <Typography
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: 500
                                        }}
                                    >
                                        H
                                    </Typography>
                                </Stack>

                                <Stack direction='column'>
                                    <Typography style={{
                                        fontSize: "14px",
                                        fontWeight: 500
                                    }}>
                                        Sold Out
                                    </Typography>
                                    <Stack direction={"row"} justifyContent={"flex-end"} spacing={0.5} onClick={openAlertDialog} mt={3} sx={{ cursor: 'pointer' }}>
                                        <WarningAmberRoundedIcon sx={{
                                            color: '#F43535',
                                            fontSize: 'medium'
                                        }} />
                                        <Typography style={{
                                            fontSize: "10px",
                                            fontWeight: 500,
                                            marginTop: '3px',
                                        }}>
                                            2 Alerts
                                        </Typography>
                                    </Stack>
                                </Stack>


                            </Box>

                        </Paper>
                    </Cell> */}
    </>
  );
};

const StyledRefreshIcon = styled(Box)(() => ({
  width: "40px",
  height: "40px",
  borderRadius: "100%",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 10px 2px rgba(3, 4, 94, 0.2)",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ":hover": {
    backgroundColor: "lightblue",
  },
}));

const StyledSearching = styled(Box)(() => ({
  height: "30px",
  aspectRatio: "1/1",
  animation: "spin 1s infinite linear",

  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));
