import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  OutlinedInput,
  Snackbar,
  SnackbarContent,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import useKeyword from "./useKeyword";
import { KeyButton, TB, TableWrapper, Td, Th } from "./KeywordStyles";
import {
  Cancel,
  Close,
  CloseRounded,
  Delete,
  KeyboardBackspace,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { PrimaryButton, useAuth } from "../sdk";
import { DialogLabel } from "../BusinessOnBooks/Styles";
import { LoadingButton } from "@mui/lab";

const options = [
  { label: "Generic", value: "generic" },
  { label: "City based", value: "city_based" },
];
const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default function Keywords() {
  const {
    searchInput,
    setSearchInput,
    page,
    count,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
    keywords,
    openModal,
    setOpenModal,
    handleAdd,
    text,
    setText,
    handleDelete,
    networkMsg,
    setnetworkMsg,
    confirmationDialog,
    setConfirmationDialog,
    setCity,
    city,
    type,
    setType,
    cityList,
    loading,
  } = useKeyword();

  const history = useHistory();
  const { permission } = useAuth();
  let canAddAndDeleteKeyword = null;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "canAddAndDeleteKeyword") {
        canAddAndDeleteKeyword = permission[key];
      }
    }
  }

  return (
    <Box
      sx={{
        padding: "70px 40px 40px 40px",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <IconButton
        disableRipple
        onClick={() => {
          history.push("/managing_organization");
        }}
        sx={{
          padding: "0px",
          color: "#808080",
        }}
      >
        <KeyboardBackspace fontSize="large" />
      </IconButton>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e?.target?.value ?? "");
            setPage(0);
          }}
          sx={{
            width: "500px",
            "& .MuiInputBase-root": {
              borderRadius: "45px",
            },
          }}
        />
        {canAddAndDeleteKeyword && (
          <KeyButton onClick={() => setOpenModal(true)}>Add Keyword</KeyButton>
        )}
      </Stack>
      <Box>
        <TableWrapper>
          <TB
            stickyHeader
            aria-label="sticky table"
            style={{
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <TableHead
              sx={{
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <TableRow>
                <Th
                  style={{
                    borderRadius: "8px 0px 0px 0px",
                  }}
                >
                  Keyword Name
                </Th>
                <Th>Keyword Type</Th>
                <Th>Added Date</Th>
                <Th>Added By (Email)</Th>
                <Th>Added By (Username)</Th>
                {canAddAndDeleteKeyword && <Th>Action</Th>}
              </TableRow>
            </TableHead>
            <TableBody>
              {keywords?.length === 0 && (
                <TableRow
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height={"200px"}
                >
                  <TableCell
                    colSpan={12}
                    sx={{
                      textAlign: "center",
                      border: "0px",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width="100%"
                    >
                      <Typography ml={1}>No Keywords Found</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {keywords.length > 0 &&
                keywords?.map((keyword) => (
                  <TableRow key={keyword.id}>
                    <Td
                      sx={{
                        maxWidth: "150px",
                      }}
                    >
                      {keyword?.name}
                    </Td>
                    <Td>
                      {keyword?.keywordType === "generic"
                        ? "Generic"
                        : "City based"}
                    </Td>
                    <Td
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {formatDate(keyword.createdAt)}
                    </Td>
                    <Td
                      sx={{
                        maxWidth: { sm: "100px", lg: "150px" },
                      }}
                    >
                      {keyword.email}
                    </Td>
                    <Td
                      sx={{
                        maxWidth: { sm: "100px", lg: "150px" },
                      }}
                    >
                      {keyword.username}
                    </Td>
                    {canAddAndDeleteKeyword && (
                      <Td>
                        <IconButton
                          disableRipple
                          onClick={() => setConfirmationDialog(keyword.id)}
                        >
                          <Delete
                            sx={{
                              color: "#163A90",
                            }}
                          />
                        </IconButton>
                      </Td>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </TB>
        </TableWrapper>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // labelDisplayedRows={({ from, to }) => null}
          // nextIconButtonProps={{
          //   disabled: eventDetails?.event_details?.events.length < rowsPerPage,
          // }}
        />
      </Box>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          ".MuiPaper-root": {
            padding: 2,
            borderRadius: "20px",
          },
        }}
        maxWidth="xs"
      >
        <IconButton
          disableRipple
          sx={{ position: "absolute", right: 10, top: "1px" }}
          onClick={() => setOpenModal(false)}
        >
          <Close />
        </IconButton>
        <DialogTitle
          sx={{
            color: "#163A90",
          }}
          textAlign="center"
        >
          Add New Keyword
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} my={1}>
            <TextField
              required
              select
              label="Keyword Type"
              size="small"
              value={type}
              onChange={(e) => {
                setType(e?.target?.value ?? "");
              }}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "45px",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {type === "city_based" && (
              <Autocomplete
                options={cityList ?? []}
                getOptionLabel={(option) => option}
                id="city"
                size="small"
                onChange={(e, value) => {
                  setCity(value);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: "45px",
                      },
                    }}
                    {...params}
                    label="Select City"
                    size="small"
                  />
                )}
              />
            )}
            <TextField
              required
              variant="outlined"
              label="Enter Keyword"
              size="small"
              value={text}
              onChange={(e) => {
                setText(e?.target?.value ?? "");
              }}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "45px",
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton
            loading={loading}
            sx={{
              padding: "5px 34px",
              backgroundColor: "#306FBC",
              color: "#fff",
              fontSize: "16px",
              fontWeight: 500,
              textTransform: "capitalize",
              borderRadius: "30px",
              fontFamily: "Roboto",
              "&:hover": {
                backgroundColor: "#163A90",
              },
              "&:disabled": {
                cursor: "auto",
                backgroundColor: "#adaaaa",
              },
            }}
            disabled={!text}
            onClick={handleAdd}
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(null)}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            <DialogLabel style={{ textAlign: "center" }}>
              Are you sure you want to remove this keyword ?
            </DialogLabel>
            <Cross
              style={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={() => setConfirmationDialog(null)}
            >
              &times;
            </Cross>
          </div>{" "}
        </DialogTitle>
        <DialogActions
          style={{
            justifyContent: "center",
            gap: "16px",
            paddingBottom: 16,
            marginBottom: "20px",
          }}
        >
          <PrimaryButton
            autoFocus
            next
            onClick={() => {
              handleDelete(confirmationDialog);
            }}
          >
            Yes
          </PrimaryButton>
          <PrimaryButton
            autoFocus
            next
            onClick={() => {
              setConfirmationDialog(null);
            }}
          >
            No
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={networkMsg}
        autoHideDuration={3000}
        onClose={() => setnetworkMsg(false)}
      >
        {networkMsg && (
          <SnackbarContent
            style={{
              backgroundColor:
                networkMsg === "Keyword Added Successfully" ||
                networkMsg === "Keyword Deleted Successfully"
                  ? "#228b22"
                  : "#CA3433",
            }}
            message={networkMsg}
          />
        )}
      </Snackbar>
    </Box>
  );
}

const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  :hover {
    cursor: pointer;
  }
`;
