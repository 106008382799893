import { Snackbar, SnackbarContent } from "@mui/material";
import React, { useCallback, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Org } from "./Admin_Refactored";
import "./App.css";

import EmailSentPage from "./Auth/EmailSentToMail";
import ForgotPasswordPage from "./Auth/ForgotPasswordPage";
import LoginPage from "./Auth/index";
import ResetLinkPage from "./Auth/ResetLinkPage";
import Business from "./BusinessOnBooks";
import ChannelComparison from "./ChannelComparison";
import ChooseHotels from "./ChooseHotel";
import CompetitorPrice from "./CompetitorPricing";
import Dashboard from "./Dashboard";
import DOWAnalysis from "./DOWAnalysis";
import DoWPickupPerformance from "./DoWPickupPerformance";
import EventCalender from "./EventCalender";
import { PastForecast } from "./ForecastPastData/component/PastForecast";
import LeadTimevsBoB from "./LeadTimevsBoB";
import LeadTimevsBoB_DoW from "./LeadTimevsBoB_DoW";
import LeadTimevsPickup from "./LeadTimevsPickup";
import LeadTimevsPickup_DoW from "./LeadTimevsPickup_DoW";
import Multiplier from "./Multipliers";
import Pace from "./Pace";
import PaceAnalysis from "./PaceAnalysis";
import PastData from "./PastData";
import PriceCheck from "./Price Checker";
import PricingRecommendation from "./PricingRecomendation";
import Setup from "./PrimarySetups";
import { ForecastSheetDaily } from "./PrimarySetups/components/ForecastSheetDaily";
import { ForecastSheetMonthly } from "./PrimarySetups/components/ForecastSheetMonthly";
import { ForecastSheetUpload } from "./PrimarySetups/components/ForecastSheetUpload";
import { NewCalendar } from "./PrimarySetups/components/NewCalendar";
import { PickUp } from "./PrimarySetups/components/PickUp";
import WarningProvider from "./Provider/context";
import { AuthConsumer, AuthProvider, Navbar } from "./sdk";
import UpdateProfile from "./UpdateProfile";
import { Uploadinfo } from "./UploadInfoPage";
import UserListPage from "./Users";
import { BookingsList } from "./Bookings/components";
import RoleManagement from "./RoleManagement";
import EventDetails from "./EventDetails";
import Keywords from "./Keyword";
import { Colors } from "./Colors";
import BroadCast from "./Broadcast";
import BroadcastDetail from "./Broadcast/BroadcastDetail";
import AdminEvents from "./AdminEvents";

function App() {
  const [refreshNavbar, setRefreshNavbar] = useState(false);
  const handleNavbarRefresh = useCallback(() => {
    setRefreshNavbar((prev) => !prev);
  }, []);

  const [pageHeader, setPageHeader] = useState("");
  return (
    <Router>
      <AuthProvider>
        <AuthConsumer>
          {(value) => (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={value.cloneMessage !== null}
              autoHideDuration={6000}
              onClose={() => {
                value.setCloneMessage(null);
              }}
            >
              <SnackbarContent
                style={{ backgroundColor: "#CA3433" }}
                message={value.cloneMessage}
              />
            </Snackbar>
          )}
        </AuthConsumer>
        <WarningProvider>
          <Navbar refreshNavbar={refreshNavbar} pageHeader={pageHeader} />
          <Switch>
            <Route path="/" exact component={LoginPage} />
            <Route path="/forgot_password" component={ForgotPasswordPage} />
            <Route path="/password_reset" component={ResetLinkPage} />
            <Route path="/link_sent_to_email" component={EmailSentPage} />

            <Route path="/managing_organization" component={Org} />
            <Route path="/keywords" component={Keywords} />
            <Route path="/all-events" component={AdminEvents} />
            <Route
              exact
              path="/hotel/:hotelId/broadcast-messages"
              component={BroadCast}
            />
            <Route
              path="/hotel/:hotelId/broadcast-messages/:Id"
              component={BroadcastDetail}
            />
            <Route path="/event-colours" component={Colors} />
            <Route path="/choose_hotel" component={ChooseHotels} />
            <Route path="/hotel/:hotelId/rate_comparison/:DATE/:FILEID">
              <CompetitorPrice setPageHeader={setPageHeader} />
            </Route>
            <Route path="/hotel/:hotelId/users" component={UserListPage} />
            <Route
              path="/hotel/:hotelId/role-management"
              component={RoleManagement}
            />
            <Route path="/hotel/:hotelId/update-profile">
              <UpdateProfile setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/rate_comparison">
              <CompetitorPrice setPageHeader={setPageHeader} />
            </Route>
            <Route
              path="/hotel/:hotelId/data_upload_information"
              component={Uploadinfo}
            />

            <Route path="/hotel/:hotelId/forecast/forecast_sheet_daily">
              <ForecastSheetDaily setPageHeader={setPageHeader} />
            </Route>
            <Route path="/hotel/:hotelId/forecast/forecast_sheet_upload">
              <ForecastSheetUpload setPageHeader={setPageHeader} />
            </Route>
            <Route path="/hotel/:hotelId/forecast/forecast_sheet_monthly">
              <ForecastSheetMonthly setPageHeader={setPageHeader} />
            </Route>

            <Route exact path="/hotel/:hotelId/new_event/calendar">
              <NewCalendar setPageHeader={setPageHeader} />
            </Route>

            <Route exact path="/hotel/:hotelId/event_calendar">
              <EventCalender setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/dashboard">
              <Dashboard setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/price_recommendation/:DATE/:FILEID"
            >
              <PricingRecommendation setPageHeader={setPageHeader} />
            </Route>

            <Route exact path="/hotel/:hotelId/price_recommendation">
              <PricingRecommendation setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/pickup_report/:FROMDATE/:TODATE">
              <Pace setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/pickup_report">
              <Pace setPageHeader={setPageHeader} />
            </Route>
            <Route path="/hotel/:hotelId/forecast/pickup/:FROMDATE/:TODATE">
              <PickUp setPageHeader={setPageHeader} />
            </Route>
            <Route path="/hotel/:hotelId/forecast/pickup">
              <PickUp setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/analytics/pace_analysis">
              <PaceAnalysis setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/analytics/dow_analysis">
              <DOWAnalysis setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/analytics/lead_time_vs_bob_daily/:DATE"
            >
              <LeadTimevsBoB setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/analytics/lead_time_vs_bob_daily"
            >
              <LeadTimevsBoB setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/analytics/lead_time_vs_bob_dow/:DATE"
            >
              <LeadTimevsBoB_DoW setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/analytics/lead_time_vs_bob_dow">
              <LeadTimevsBoB_DoW setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/analytics/lead_time_vs_pickup_daily/:DATE"
            >
              <LeadTimevsPickup setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/analytics/lead_time_vs_pickup_daily"
            >
              <LeadTimevsPickup setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/analytics/lead_time_vs_pickup_dow/:DATE"
            >
              <LeadTimevsPickup_DoW setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/analytics/lead_time_vs_pickup_dow"
            >
              <LeadTimevsPickup_DoW setPageHeader={setPageHeader} />
            </Route>

            <Route
              exact
              path="/hotel/:hotelId/analytics/dow_pickup_performance"
            >
              <DoWPickupPerformance setPageHeader={setPageHeader} />
            </Route>

            <Route exact path="/hotel/:hotelId/business_on_books/:DATE/:FILEID">
              <Business setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/bookings">
              <BookingsList setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/business_on_books">
              <Business setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/pastdata">
              <PastData setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/forecast/pastdata">
              <PastForecast setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/price-checker">
              <PriceCheck setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/event-details">
              <EventDetails setPageHeader={setPageHeader} />
            </Route>
            <Route exact path="/hotel/:hotelId/channel-comparison">
              <ChannelComparison setPageHeader={setPageHeader} />
            </Route>
            <Route
              exact
              path="/hotel/:hotelId/multipliers"
              component={Multiplier}
            />
            <Route path="/hotel/:hotelId/setup">
              <Setup
                handleNavbarRefresh={handleNavbarRefresh}
                setPageHeader={setPageHeader}
              />
            </Route>
            <Route exact path="/hotel">
              <Setup
                handleNavbarRefresh={handleNavbarRefresh}
                setPageHeader={setPageHeader}
              />
            </Route>
          </Switch>
        </WarningProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
