import {
  Box,
  Breadcrumbs,
  Stack,
  TextField as TF,
  Typography,
} from "@mui/material";
import { Modal } from "@mui/material";
import {
  AddOutlined,
  ColorLens,
  EventNote,
  FormatShapes,
} from "@mui/icons-material";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Autocomplete } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoadingPage, useAuth } from "../../sdk";
import { ModelBody } from "./ModelBody";
import { styled } from "@mui/system";
import {
  ButtonContainer,
  CloneButtonContainer,
  Grid,
  HotelSection,
  Link,
  Main,
  OrganizationContainer,
  OrganizationName,
  OrganizationSection,
  Page,
  RightSection,
} from "./Styles";

const LinkNew = styled(Link)`
  display: flex;
  justify-content: space-evenly;
  height: 40px;
  width: 100%;
`;
const TextField = styled(TF)`
  input {
    border: none;
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 16px/20px Roboto",
  },
}));

const OrganizationNameNew = styled(OrganizationName)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  letter-spacing: 0.46px;
  text-transform: capitalize;

  color: #000000;
`;
const GridNew = styled(Grid)`
  padding-right: 24px;
`;
const OrganizationSectionNew = styled(OrganizationSection)`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(3, 4, 94, 0.1);
  border-radius: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const OrganizationContainerNew = styled(OrganizationContainer)`
  box-sizing: border-box;
  border-radius: 8px;
  height: 48px;
  border: 1px solid #0000004d;
  // margin-top: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  letter-spacing: 0.46px;
  text-transform: capitalize;
  padding-left: 20px;
  color: #000000;
  &:hover {
    background: #2f6fbc;
    color: #ffffff !important;

    &.MuiSvgIcon-root {
      color: #fffff !important;
    }
  }
`;
const ArrowForwardIosIconNew = styled(ArrowForwardIos)`
  height: 16px;
`;
const AddHotel = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 171% */

  letter-spacing: 0.46px;
  text-transform: capitalize;

  /* Primary/Contrast */
  color: #ffffff;
`;

const TextFieldNew = styled(TextField)`
  background: #ffffff;
  border: none;
  box-shadow: 1px 2px 4px 1px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  padding-left: 8px;
  height: 40px;
  padding-top: 4px;
`;
const Text = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.46px;
  text-transform: capitalize;

  color: #306fbc;
`;

export function ManagingOrganizationPage({
  setSelectedOrganizationName,
  setnetworkMsg,
  setOpen,
}) {
  const {
    hotels,
    token,
    authFetch,
    permission,
    selectHotel,
    updateHotels,
  } = useAuth();
  const history = useHistory();
  const [Loading, setLoading] = useState();
  const { ORGID } = useParams();
  const [hotelList, setHotelList] = useState([]);
  const [hotelListCloned, setHotelListCloned] = useState([]);
  const [managingOrganizations, setManagingOrganizations] = useState([]);
  const [differentOrganization, setDifferentOrganization] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState();
  const [
    selectedManagingOrganization,
    setSelectedManagingOrganization,
  ] = useState(() => {
    if (ORGID) {
      return ORGID;
    } else {
      return 0;
    }
  });
  useEffect(() => {
    if (!token) {
      return;
    }
    if (token) {
      hotelList.forEach((_, index) => {
        if (
          hotelList[index + 1] &&
          hotelList[index].organizationId !==
            hotelList[index + 1].organizationId
        ) {
          setDifferentOrganization(true);
        }
      });
    }
  }, [token]);
  const [openModel, setOpenModel] = useState(false);

  let cloneHotel = false;

  let listOfOrg = null,
    searchHotel = null,
    addHotel = null,
    viewEventColours = null,
    viewAllEvents = null;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "listOfOrg") {
        listOfOrg = permission[key];
      }
      if (permission[key].name === "searchHotel") {
        searchHotel = permission[key];
      }
      if (permission[key].name === "addHotel") {
        addHotel = permission[key];
      }
      if (permission[key].name === "cloneHotel") {
        cloneHotel = true;
      }
      if (permission[key].name === "viewEventColours") {
        viewEventColours = true;
      }
      if (permission[key].name === "viewAllEvents") {
        viewAllEvents = true;
      }
    }
  }
  const handleHotelChange = ({ name: value }) => {
    const hotelName = value.split("(")[0]?.trim();
    const selectedHotel = hotelList.find(
      (hotel) => hotel.name.trim() === hotelName
    );
    if (selectedHotel) {
      selectHotel(selectedHotel, true);
      setSelectedHotelId(selectedHotel.id);
    } else {
      selectHotel(null);
      setSelectedHotelId(null);
    }
  };
  const defaultHotelProps = {
    options: [
      ...hotelList.sort((a, b) =>
        String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1
      ),
      // .map((hotel) => {
      //   return differentOrganization
      //     ? hotel.name + " (" + hotel.organizationName + ")"
      //     : hotel.name;
      // }),
    ],
    getOptionLabel: (option) =>
      differentOrganization
        ? option.name + " (" + option.organizationName + ")"
        : option.name ?? option,
    getOptionKey: (option) => option.id,
  };

  const sortHotels = useCallback((a, b, inputVal) => {
    if (!a || !b || !inputVal) return 0;
    const pos1 = a?.name.toLowerCase().indexOf(inputVal.toLowerCase());
    const pos2 = b?.name.toLowerCase().indexOf(inputVal.toLowerCase());

    if (pos1 < pos2) return -1;
    else if (pos1 > pos2) return 1;
    else a.name.localeCompare(b.name);
  }, []);
  const searchedHotels = useCallback(
    (options, inputValue) => {
      const filteredHotels = options.filter((option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      return filteredHotels.sort((a, b) => sortHotels(a, b, inputValue));
    },
    [sortHotels]
  );

  useEffect(() => {
    if (!token) {
      return;
    }
    getAllClonedHotels();
    if (listOfOrg) {
      getManagingOrganization();
      getAllHotels();
    } else {
      setHotelList(hotels);
    }
  }, [token, listOfOrg]);

  const getAllHotels = async () => {
    const { get } = await authFetch({
      path: `/hotel`,
    });
    const { data, error } = await get();
    if (data) {
      setHotelList(data);
      localStorage.setItem("hotels", JSON.stringify(data));
      updateHotels();
    } else {
      setHotelList([]);
    }
  };
  const getAllClonedHotels = async () => {
    const { get } = await authFetch({
      path: `/clone-hotel`,
    });
    const { data, error } = await get();
    if (data) {
      setHotelListCloned(data);
    } else {
      setHotelListCloned([]);
    }
  };

  async function getManagingOrganization() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/all-managing-org`,
    });
    const { data, error } = await get();
    if (data) {
      setManagingOrganizations(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <ClassWrapper>
      <Page>
        <HotelSection>
          <Main style={{ height: "10%" }}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              style={{ marginLeft: "20px" }}
            >
              <Link
                style={{ fontFamily: "Roboto" }}
                color="inherit"
                to="/managing_organization"
              >
                Home
              </Link>
            </Breadcrumbs>
            <RightSection>
              {searchHotel && (
                <Autocomplete
                  {...defaultHotelProps}
                  noOptionsText={"No Hotels Available"}
                  style={{
                    width: addHotel ? "60%" : "100%",
                    font: "Roboto",
                    maxWidth: "320px",
                    marginRight: "10px",
                  }}
                  freeSolo={true}
                  onChange={(event, newValue) => {
                    handleHotelChange(newValue);
                  }}
                  filterOptions={(option, { inputValue }) => {
                    return searchedHotels(option, inputValue);
                  }}
                  className="dropdownStyle"
                  renderInput={(params) => (
                    <TextFieldNew
                      variant="standard"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      placeholder="Search Hotel"
                    />
                  )}
                />
              )}

              {cloneHotel && (
                <CloneButtonContainer
                  style={{
                    border: "1px solid #306FBC",
                    boxShadow:
                      "0px 3px 1px -2px rgba(48, 111, 188, 0.2), 0px 2px 2px rgba(48, 111, 188, 0.2), 0px 1px 5px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <LinkNew to="#" onClick={() => setOpenModel(!openModel)}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "7px",
                      }}
                    >
                      <img
                        style={{ width: "19px", height: "22px" }}
                        src="/assets/ContentCopyIcon.svg"
                        alt="clone button"
                      />
                      <Text>Clone Hotel</Text>
                    </div>
                  </LinkNew>
                </CloneButtonContainer>
              )}
              <ButtonContainer
                style={{
                  background: "#2F6FBC",
                  boxShadow:
                    "0px 3px 1px -2px rgba(48, 111, 188, 0.2), 0px 2px 2px rgba(48, 111, 188, 0.2), 0px 1px 5px rgba(48, 111, 188, 0.2)",
                  borderRadius: "8px",
                  margin: "0px 10px 0px -1px",
                }}
              >
                <Link to="/keywords">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <FormatShapes style={{ width: "20px", color: "white" }} />
                    <AddHotel>Keywords</AddHotel>
                  </div>
                </Link>
              </ButtonContainer>
              {viewEventColours && (
                <ButtonContainer
                  style={{
                    background: "#2F6FBC",
                    boxShadow:
                      "0px 3px 1px -2px rgba(48, 111, 188, 0.2), 0px 2px 2px rgba(48, 111, 188, 0.2), 0px 1px 5px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                    margin: "0px 10px 0px 6px",
                  }}
                >
                  <Link to="/event-colours">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <ColorLens style={{ width: "20px", color: "white" }} />
                      <AddHotel>Colours</AddHotel>
                    </div>
                  </Link>
                </ButtonContainer>
              )}
              {viewAllEvents && (
                <ButtonContainer
                  style={{
                    background: "#2F6FBC",
                    boxShadow:
                      "0px 3px 1px -2px rgba(48, 111, 188, 0.2), 0px 2px 2px rgba(48, 111, 188, 0.2), 0px 1px 5px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                    margin: "0px 10px 0px 6px",
                  }}
                >
                  <Link to="/all-events">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <EventNote style={{ width: "20px", color: "white" }} />
                      <AddHotel>All Events</AddHotel>
                    </div>
                  </Link>
                </ButtonContainer>
              )}

              {addHotel && (
                <ButtonContainer
                  style={{
                    background: "#2F6FBC",
                    boxShadow:
                      "0px 3px 1px -2px rgba(48, 111, 188, 0.2), 0px 2px 2px rgba(48, 111, 188, 0.2), 0px 1px 5px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <Link to="/hotel">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddOutlined style={{ width: "20px", color: "white" }} />
                      <AddHotel> Add Hotel</AddHotel>
                    </div>
                  </Link>
                </ButtonContainer>
              )}
            </RightSection>
          </Main>
          {!Loading ? (
            <OrganizationSectionNew>
              <GridNew>
                <OrganizationContainerNew
                  onClick={() => {
                    setSelectedManagingOrganization("all");
                    setSelectedOrganizationName("All");
                  }}
                  to="/managing_organization/all/chain"
                >
                  All
                  <ArrowForwardIosIconNew />
                </OrganizationContainerNew>
                {managingOrganizations
                  .sort((a, b) =>
                    String(a.name).toLowerCase() > String(b.name).toLowerCase()
                      ? 1
                      : -1
                  )
                  .map((organization, index) => (
                    <OrganizationContainerNew
                      key={index}
                      onClick={() => {
                        setSelectedManagingOrganization(organization.id);
                        setSelectedOrganizationName(organization.name);
                      }}
                      to={`/managing_organization/${organization.id}/chain`}
                    >
                      {organization.name}
                      <ArrowForwardIosIconNew />
                    </OrganizationContainerNew>
                  ))}
              </GridNew>
            </OrganizationSectionNew>
          ) : (
            <LoadingPage />
          )}
          <Modal
            open={openModel}
            onClose={() => {
              setOpenModel(false);
            }}
          >
            <div>
              <ModelBody
                hotelList={hotelList}
                differentOrganization={differentOrganization}
                selectHotel={selectHotel}
                openModel={openModel}
                setOpenModel={setOpenModel}
                setLoading={setLoading}
                hotelListCloned={hotelListCloned}
                setnetworkMsg={setnetworkMsg}
                setOpen={setOpen}
              />
            </div>
          </Modal>
        </HotelSection>
      </Page>
    </ClassWrapper>
  );
}
